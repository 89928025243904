import Amplify from '@aws-amplify/core'
import { logIn } from './services/log-in'

import { ErrorInformation, LoginInformation } from '../types'
import { addFeedback, removeFeedback } from './feedback'
import { removeLoading } from './loading'

const API_METHOD = 'getUser'

function getListener(xhr: XMLHttpRequest, clientname: string, username: string, password: string) {
	return async () => {
		const isRequestDone = xhr.readyState === XMLHttpRequest.DONE
		if (!isRequestDone) return

		switch (xhr.status) {
			case 200: {
				const [response]: LoginInformation[] = JSON.parse(xhr.responseText)

				if (!response) {
					removeLoading()
					addFeedback('Invalid credentials.')
					break
				}

				const { region, poolID, appClientID } = response
				const amplifyData = {
					Auth: {
						region,
						userPoolId: poolID,
						userPoolWebClientId: appClientID
					}
				}
				Amplify.configure(amplifyData)

				try {
					await logIn(username, password)
					window.location.href = `/${clientname}/`
				} catch (error) {
					removeLoading()
					addFeedback('Invalid credentials.')
				}

				break
			}
			default: {
				const errorResponse: ErrorInformation | null = JSON.parse(xhr.responseText)

				removeLoading()

				console.error(`Error ${xhr.status}: ${errorResponse?.message || xhr.statusText || 'No error message.'}`)
				break
			}
		}
	}
}

export function init() {
	const form: HTMLFormElement | null = document.querySelector('#auth-form')

	if (form) {
		form.addEventListener('submit', event => {
			event.preventDefault()
			removeFeedback()

			const { value: clientName } = form.elements.namedItem('clientname') as HTMLFormElement
			const { value: username } = form.elements.namedItem('username') as HTMLFormElement
			const { value: password } = form.elements.namedItem('password') as HTMLFormElement

			if (clientName === '' || username === '' || password === '') {
				// One of the fields is empty
				addFeedback('Invalid credentials.')
				return
			}

			const xhr = new XMLHttpRequest()

			const endpoint = new URL(`${import.meta.env.VITE_API_VERSION}/${API_METHOD}`, import.meta.env.VITE_API_ENDPOINT)
			endpoint.searchParams.append('username', username)
			endpoint.searchParams.append('client', clientName)

			xhr.open('GET', endpoint.href)
			xhr.setRequestHeader('x-api-key', import.meta.env.VITE_API_KEY)
			xhr.setRequestHeader('Content-Type', 'application/json')

			// XHR is way more performant than fetch (according to our benchmarks)
			xhr.addEventListener('readystatechange', getListener(xhr, clientName, username, password))

			form.classList.add('loading')

			xhr.send()
		})
	} else {
		console.error(
			'[RexData] No form was found. Try reloading the page. If the problem persists, contact the developers.'
		)
	}
}
