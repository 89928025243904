import 'babel-polyfill'
import { init as initAuthForm } from './auth-form'
import { registerServiceWorker } from './register-service-worker'
// import { idbPOC2 } from './idbPOC'

document.addEventListener('DOMContentLoaded', () => {
	initAuthForm()
})

/**
 * Register service worker after load to prevent
 * performance problems
 * [https://web.dev/service-workers-registration/]
 * @since 0.0.8
 */
window.addEventListener('load', async() => {
	if (import.meta.env.PROD) {
		await registerServiceWorker()
	}
	// idbPOC2()
})
