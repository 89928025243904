export function addFeedback(feedbackText: string) {
	const feedbackBox: HTMLElement | null = document.getElementById('login-feedback')

	if (feedbackBox) {
		feedbackBox.textContent = feedbackText
		feedbackBox.style.display = 'block'
	} else {
		console.warn('No feedback box found')
	}
}

export function removeFeedback() {
	const feedbackBox: HTMLElement | null = document.getElementById('login-feedback')

	if (feedbackBox) {
		feedbackBox.textContent = ''
		feedbackBox.style.display = 'none'
	} else {
		console.warn('No feedback box found')
	}
}
