export async function registerServiceWorker() {
	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.register(
				'service-worker.js',
				{
				scope: './',
				}
			);
			if (registration.installing) {
				console.log('[RexData]: Service worker installing');
			} else if (registration.waiting) {
				console.log('[RexData]: Service worker installed');
			} else if (registration.active) {
				console.log('[RexData]: Service worker active');
			}
		} catch (error) {
			console.error(`[RexData]: Registration failed with ${error}`);
		}
	}
}
